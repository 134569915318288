const intro = {
	header: "Welcome to Your Next Favorite Story",
	body: "<p>Escape from everyday life into far off worlds and magical lands. Adventure, romance, and wonder await you on your journey through the realms of an overactive imagination.</p><p>Your next favorite story is here, at <strong>Hemisphere Studios</strong>.</p>",
	logo: "http://www.hemispherestudios.com/images/logo-webcomic.png"
};
			
const properties = [
	{
		id: "JE",
		name: "Jax Edison",
		genre: "Fantasy, Adventure, Romance",
		description: "<p>A terrible tragedy leads Jax Edison to the lonely life of a wandering adventurer, until she rescues a young dryad named Nikki Bones from the schemes of a corrupt shaman. Once Nikki plants herself in Jax’s life, the gunfighting magician discovers happiness may not be so elusive, and even the most broken heart can still heal.</p><p>Unfortunately, the machinations of the powerful conspire to pull Jax and Nikki away from any attempt at a peaceful life and they find themselves drawn into a series of adventures that will ultimately lead both women to a destiny they never thought possible and a love that will save the world.</p><p><i>Jax Edison</i> is available as a trilogy of e-books.</p>",
		blurb: "<p>A gun-wielding magician on the frontier of a collapsing empire channels rage and vengeance into a lonely mission to champion the powerless, but the love of her new faery companion and an ever-growing list of people who want them dead will drive her deeper into a world she is ultimately destined to save.</p>",
		image: "http://www.hemispherestudios.com/images/JaxPropertySpotlight.jpg",
		thumbnail: "http://www.hemispherestudios.com/images/JaxProperty.png",
		media: [
			{
				title: "Two Gun Magician",
				img: "http://www.hemispherestudios.com/images/JaxCover01.png",
				url: "http://www.amazon.com/dp/B00HKRO2DW"
			},
			{
				title: "Outlaw Prophet",
				img: "http://www.hemispherestudios.com/images/JaxCover02.png",
				url: "http://www.amazon.com/dp/B00N6GVZM2"
			},
			{
				title: "The Celestial Frontier",
				img: "http://www.hemispherestudios.com/images/JaxCover03.png",
				url: "http://www.amazon.com/dp/B018LFTJ9S"
			},
		]
	},
	{
		id: "AM",
		name: "Amorlia",
		genre: "Fantasy, Adventure, Superhero, Western",
		description: "<p>When the Monarch of the Land Vega is killed in a treacherous attack, his daughter Artemis is left for dead by the usurpers and the soul of Vega’s Champion is imprisoned by a mad goddess.</p><p>Artemis Vega will traverse the wilds of Amorlia and journey to the realms beyond to learn the truth of her divine nature, but only after she  braves the deepest levels of Hell to rescue her beloved Champion will she finally gather her allies and win back her throne.</p><p><i>Amorlia</i> is avalable as a trilogy of audiobooks, narrated by Em Eldridge.</p>",
		blurb: "<p>An exiled demigoddess must conquer Hell to rescue the soul of her beloved champion and liberate her people from the tyranny of a brutal zealot.</p>",
		image: "http://www.hemispherestudios.com/images/AmorliaPropertySpotlight.jpg",
		thumbnail: "http://www.hemispherestudios.com/images/AmorliaProperty.png",
		media: [
			{
				title: "Princess in Exile",
				img: "http://www.hemispherestudios.com/images/PrincessCover.png",
				url: "http://www.audible.com/pd?asin=B00CA6XR4I"
			},
			{
				title: "Monarch Rising",
				img: "http://www.hemispherestudios.com/images/MonarchCover.png",
				url: "http://www.audible.com/pd?asin=B00CTW4MEC"
			},
			{
				title: "Heir to the High Queen",
				img: "http://www.hemispherestudios.com/images/HeirCover.png",
				url: "http://www.audible.com/pd?asin=B00D3PE1A4"
			},
		]
	},
	{
		id: "WF",
		name: "The Windfarmer",
		genre: "Science Fiction, Adventure, Comedy, Drama",
		description:"<p>When an indentured windfarmer runs off with an Appalachian refugee, it’s up to hapless HR representative Jase Logan to track him down, sending him on a series of misadventures through the former United States.</p><p>A power-mad CEO and an enterprising Appalachian terrorist will do their best to destroy him, but with luck, the kindness of strangers, and the loyalty of what friends he has left Jase will find a way to bring hope to a troubled world and gain closure on a tragedy from his past.</p><p><i>The Windfarmer</i> is available as an e-book.",
		blurb: "<p>A sci-fi dramedy about a hapless HR representative and his misadventures through the former United States.</p>",
		footer: "<i>The Windfarmer</i> is Copyright &copy; Chris Wichtendahl",
		image:"http://www.hemispherestudios.com/images/WindfarmerCoverSpotlight.jpg",
		thumbnail: "http://www.hemispherestudios.com/images/WindfarmerCover.png",
		media: [
			{
				title: "The Windfarmer",
				img: "http://www.hemispherestudios.com/images/WindfarmerCover.png",
				url: "https://www.amazon.com/dp/B07NKHF5JF"
			}
		]
	},
	{
		id: "TC",
		name: "The Colonist",
		genre: "Science Fiction, Adventure, Comedy, Drama",
		description:"<p>When seasoned Colonial Custodian Rec Valentine is left stranded at the edge of a declining galactic empire with only a cheerful AI for company, he must find a way to hack his living quarters after an imperial edict turns his AI pal homicidal.</p><p><i>The Colonist</i> is available as a short e-book.</p>",
		blurb: "<p>A colonist stranded at the edge of a galactic empire must find a way to hack his living quarters before his cheerful AI companion turns homicidal.</p>",
		footer: "<i>The Colonist</i> is Copyright &copy; Chris Wichtendahl",
		image:"http://www.hemispherestudios.com/images/ColonistCoverSpotlight.jpg",
		thumbnail: "http://www.hemispherestudios.com/images/ColonistCover.png",
		media: [
			 {
			 	title: "The Colonist",
				img: "http://www.hemispherestudios.com/images/ColonistCover.png",
				url: "https://www.amazon.com/dp/B085VRV7LV"
			 }
		]
	},
];

const comics = [
	{
		id: "ATSS",
		title: "Among the Silver Stars",
		propertyUrl: "/properties/among-the-silver-stars",
		url: "/webcomics/among-the-silver-stars",
		blurb: "<p>Josh Carson lived a childhood adventure that most boys only dream of. Then he grew up and his dream became a nightmare.</p>",
		keywords: "webcomic, sci-fi, superhero",
		description: "<p>When Josh Carson is abducted in front of his pregnant wife by the aliens who used to turn him into a superhero as a child, he learns the devastating truth about a life he thought he’d finally put behind him.</p>",
		collaborator: "<p>A 54-page webcomic drawn by <a href=\"http://ariaxy.deviantart.com/\" target=\"_blank\">Ariel Iacci</a>. Copyright &copy; Chris Wichtendahl &amp; Ariel Iacci.</p>",
		thumbnail: "http://www.hemispherestudios.com/images/defender-index.jpg",
		cover: "http://www.hemispherestudios.com/images/webcomic/defender/share.jpg"
	},
	{
		id: "DH",
		title: "Dear Husband",
		propertyUrl: "/properties/dear-husband",
		url: "/webcomics/dear-husband",
		blurb: "<p>From deep in the blighted wastes of America's Heartland, a soldier on the front line of the Robot War writes home one last time.</p>",
		keywords: "webcomic, sci-fi, robots",
		description: "<p>In the final days of the Robot War, a soldier in the blighted wastes of America's Heartland writes home to her family for the last time, knowing that though she will soon give her life for them, her death will not save them.</p>",
		collaborator: "<p>A 13-page webcomic drawn by <a href=\"http://orodriguez.daportfolio.com/\" target=\"_blank\">Ozzie Rodriguez</a>. Copyright &copy; Chris Wichtendahl &amp; Ozzie Rodriguez.</p>",
		thumbnail: "http://www.hemispherestudios.com/images/husband-index.jpg",
		cover: "http://www.hemispherestudios.com/images/webcomic/husband/share.jpg"
	},
	{
		id: "TOM",
		title: "The Twilight of Magic",
		propertyUrl: "/properties/the-twilight-of-magic",
		url: "/webcomics/the-twilight-of-magic",
		blurb: "<p>The last wizard ends his life in the company of strange children, leaving a new generation to reinvent magic.</p>",
		keywords: "webcomic, sci-fi, fantasy, magic",
		description: "<p>The old wizard had lived too long, and his time had run out, with no one to succeed him when he died. He believed times had changed, and there was no place for magic in this interconnected world of technological wonders. Then he met the boy, and realized he had something left to learn.</p>",
		collaborator: "<p>An 8-page webcomic drawn by <a href=\"http://orodriguez.daportfolio.com/\" target=\"_blank\">Ozzie Rodriguez</a>. Copyright &copy; Chris Wichtendahl &amp; Ozzie Rodriguez.</p>",
		thumbnail: "http://www.hemispherestudios.com/images/twilight-index.jpg",
		cover: "http://www.hemispherestudios.com/images/webcomic/share.jpg"
	}
]

export { intro, properties, comics };