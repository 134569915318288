import React, { Component } from 'react';

class Swiper extends Component {
	constructor(props) {
		super(props);
		this.xDown = null;
        this.yDown = null;
		this.handleTouchStart = this.handleTouchStart.bind(this);
		this.handleTouchMove = this.handleTouchMove.bind(this);
	}
	
	onLeft() {
        this.props.navProps.navNext();
    }

    onRight() {
        this.props.navProps.navBack();
    }

    onUp() {
        return this;
    }

    onDown() {
        return this;
    }
	
	handleTouchStart(evt) {
		this.xDown = evt.touches[0].clientX;
        this.yDown = evt.touches[0].clientY;
	}

    handleTouchMove(evt) {
        if ( ! this.xDown || ! this.yDown ) {
            return;
        }

        var xUp = evt.touches[0].clientX;
        var yUp = evt.touches[0].clientY;

        this.xDiff = this.xDown - xUp;
        this.yDiff = this.yDown - yUp;

        if ( Math.abs( this.xDiff ) > Math.abs( this.yDiff ) ) { // Most significant.
            if ( this.xDiff > 0 ) {
                this.onLeft();
            } else {
                this.onRight();
            }
        } else {
            if ( this.yDiff > 0 ) {
                this.onUp();
            } else {
                this.onDown();
            }
        }

        // Reset values.
        this.xDown = null;
        this.yDown = null;
    }
	
	render() {
		let { pages } = this.props;
		return (
			<div className="page" onTouchStart={this.handleTouchStart} onTouchMove={this.handleTouchMove}>
				{pages.map((page, p) => <img key={p} src={page} />)}
			</div>
		);
	}
};

export default Swiper;