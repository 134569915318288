export default {
	"comics": [
		{
			"title": "Among the Silver Stars",
			"dir": "http://www.hemispherestudios.com/images/webcomic/defender",
			"cover": "cover.jpg",
			"loading": "loading.jpg",
			"issues": [
				{
					"start": 1,
					"end": 22,
					"cover": "chaps/1.jpg",
				},
				{
					"start": 23,
					"end": 36,
					"cover": "chaps/2.jpg"
				},
				{
					"start": 37,
					"end": 51,
					"cover": "chaps/3.jpg"
				},
				{
					"start": 52,
					"end": 54,
					"cover": "chaps/4.jpg"
				}
				
			]
		},
		{
			"title": "Dear Husband",
			"dir": "http://www.hemispherestudios.com/images/webcomic/husband",
			"cover": "cover.jpg",
			"loading": "../loading.jpg",
			"issues": [
				{
					"start": 2,
					"end": 13,
					"cover": "cover.jpg",
				}
				
			]
		},
		{
			"title": "The Twilight of Magic",
			"dir": "http://www.hemispherestudios.com/images/webcomic",
			"cover": "cover.jpg",
			"loading": "loading.jpg",
			"issues": [
				{
					"start": 1,
					"end": 8,
					"cover": "cover.jpg",
				}
				
			]
		}
	]
};