import React, { Component, Fragment } from 'react';
import Issue from './Issue';
import Nav from './Nav';
import Lander from './Lander';

const buildURL = (dir, file) => {
	return `${dir}/${file}`;
};

const getPages = (comic, selected) => {
	if(!Number.isInteger(selected)) {
			return [];
		}
	let cover = buildURL(comic.dir, comic.issues[selected].cover);
	let pages = [{"pg": cover, "pn": null}];
	
	let n = comic.issues[selected].start;
	let a = 1;
		  while(n < (comic.issues[selected].end + 1)) {
			  let page = buildURL(comic.dir, `${n}.jpg`);
			  pages[a] = {
				  "pg": page,
				  "pn": n
			  };
			  a++;
			  n++;
		  }
	return pages;
};

const getViewPort = () => {
	let mobile = window.matchMedia('(min-device-width: 320px)', '(max-device-width: 767px)').matches;
	let portrait = window.matchMedia('(orientation: portrait)').matches;
	return { mobile, portrait };
}

class Comic extends Component {
	constructor(props) {
		super(props);
		this.state = {
			index: 0,
			selected: this.props.selectedIssue,
			pages: getPages(this.props.comic, this.props.selectedIssue)
		}
		this.buildDataForPageRender = this.buildDataForPageRender.bind(this);
		this.selectIssue = this.selectIssue.bind(this);
		this.navBack = this.navBack.bind(this);
		this.navNext = this.navNext.bind(this);
	}
	selectIssue(selected, flow) {
		let comic = this.props.comic;
		if((selected < 0) || (selected === comic.issues.length)){
			this.props.selectComic(null);
		}
		let ind = 0;
		Promise.resolve()
		.then(() => getPages(comic, selected))
		.then(pages => {
			if (flow === 'back') {
				ind = (pages.length - 1);
			}
			window.scrollTo({
				top: 0,
				behavior: "smooth",
			});
			this.setState({index: ind, selected, pages});
		});
	}
	navBack() {
		let index = (this.state.index - 1);
		let { selected } = this.state;
		if(index >= 0){
			this.setState({index});
		} else {
			selected --;
			this.selectIssue(selected, 'back');
		}
		
	}
	navNext() {
		let index = (this.state.index + 1);
		let { selected, pages } = this.state;
		let { comic } = this.props;
		if(index !== pages.length){
			this.setState({index});
		} else {
			selected ++;
			this.selectIssue(selected);
		}
	}
	
	buildDataForPageRender() {
		let { index, selected, pages } = this.state;
		let { dir, cover, loading, issues, title } = this.props.comic;
		console.log("pages", pages);
		let pagesData = [];
		let className = "";
		if(Number.isInteger(selected)) {
			let page = pages[index];
			let pn = page["pn"];
			let pg = page["pg"];
			if(pn == null) {
				pn = (issues[selected].start - 1);
			}
			let next = (pn < issues[selected].end) ? pages[(index+1)]["pg"] : buildURL(dir, loading);
			className = title.replace(/\s+/g, '-').toLowerCase();
			pagesData = [pg, next];
		}
		console.log("pagesData", pagesData);
		pagesData = pages.map(page => {
			return page["pg"];
		});
		console.log("pagesData", pagesData);
		return { pagesData, className };
	}
	
  render() {
	  let { selected } = this.state;
	  let { comic, metadata } = this.props;
	  let { issues, dir } = comic;
	  let { title, description } = metadata;
	  let { pagesData, className } = this.buildDataForPageRender();
	return (
		<Fragment>
		{(!Number.isInteger(selected)) ?
		 	<Lander arr={issues} title={title} description={description} selectItem={this.selectIssue} dir={dir}/>
		 :
			<Issue pages={pagesData} selected={selected} className={className} selectItem={this.selectIssue} navBack={this.navBack} navNext={this.navNext}/>
//			<Fragment>
//				<Issue pages={pagesData} className={className} navBack={this.navBack} navNext={this.navNext}/>
//				<Nav navBack={this.navBack} navNext={this.navNext}/>
//			</Fragment>
		}
   		</Fragment>
    );
  }
}

export default Comic;
