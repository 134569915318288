import React, { Component } from 'react';
import Page from './Page';

class Issue extends Component {
	
  render() {
	let { pages, className, navBack, navNext, selectItem, selected } = this.props;
	let bookClass = `book ${className}`;
	const nextIssue = (selected + 1);
	const renderedPages = pages.map(page => {
	  return <img src={page} />
	});
	return (
		<div className={bookClass}>
			{renderedPages}
		{(className === 'among-the-silver-stars' && selected < 3) &&
			<span className="issue-nav" onClick={() => selectItem(nextIssue)}>Next Issue &rarr;</span>
		}
		</div>
    );
  }
}

export default Issue;
