import React, { Fragment, useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { intro, properties, comics } from './data.js';
import Spotlight from './components/Spotlight';
import Reader from './Webcomics/Reader';
import './styles.css';

function App() {
	const [id, setId] = useState("WF");
	const handleClick = (propId) => {
		setId(propId);
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	 };
	 
	const getSpotlight = () => {
		const featuredProperty = properties.filter(prop => prop.id === id);
		if (typeof(featuredProperty[0]) === 'undefined') {
			return null;
		}
		return featuredProperty[0];
	};
	const Webcomics = ({isStories=false}) => {
		let shelf = comics.map(comic => {
			const { title, cover, blurb, url, collaborator } = comic;
			return (	
			<div class="item">
				<div className="image">
					<a href={url}>
					<img src={cover} />
					<h4>{title}</h4>
					</a>
				</div>
				<div className="text">
					<div dangerouslySetInnerHTML={{__html: blurb}} />
					{collaborator &&
					<div dangerouslySetInnerHTML={{__html: collaborator}}/>
					}
				</div>
			</div>
			);
		});
		return (
			<Fragment>
			{!isStories &&
			<Intro/>
			}
			<h2>FREE Webcomics</h2>
			<p>Your next favorite story doesn't have to cost an arm and a leg.</p>
			<div className='comics'>
				{shelf}
			</div>
			</Fragment>
		);
	};
	const spotlight = getSpotlight();
	const Properties = () => {
		const renderedProperties = properties.map(prop => {
			if (prop.id !== id){
				const { name, image, blurb } = prop;
				return (
					<div>
						<a onClick={() => handleClick(prop.id)}>
						<img src={image} />
						<h4>{name}</h4>
						</a>
					</div>
				);
			}
		});
		return renderedProperties;
	}
	const { header, body, logo } = intro;
	const Intro = () => {
		return (
			<Fragment>
			<h2>{header}</h2>
			<div dangerouslySetInnerHTML = {{__html: body}} />
			<hr/>
			</Fragment>
		);
	}
	
	const Stories = () => {
		return (
			<Fragment>
			<Intro/>
			<Spotlight spotlight={spotlight} />
			<hr/>
			<h2 className="section">More Stories</h2>
			<div className="properties">
				<Properties/>
			</div>
			<hr/>
			<Webcomics isStories={true}/>
			</Fragment>
		);	
	}
	return (
	  <Router>
		<div id="hs">
			<header><a href="/"><img src={logo} /></a><h1>Hemisphere Studios</h1></header>
			<Routes>
			<Route exact path="/webcomics" element={<Webcomics/>} />
     		<Route path="/webcomics/:comic" element={<Reader/>} />
			<Route path="/*" element={<Stories/>} />
			</Routes>
		</div>
	</Router>
	);
}

export default App;
