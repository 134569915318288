import React, { Component } from 'react';
import Swiper from './Swiper';

class Page extends Component {
	constructor(props) {
		super(props);
	}
  render() {
	let { pages, navProps } = this.props;
	const renderedPages = pages.map(page => {
	  return <img src={page} />
	});
	return renderedPages;
		
//	  if (navProps) {
//		  return (<Swiper navProps={navProps} pages={pages} />);
//	  }
//	 return (<img src={pages[0]} />);
    
  }
}

export default Page;
