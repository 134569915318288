import React, { Component, Fragment } from 'react';
import Comic from './Comic';
import comicsData from './comicsData';
import { comics as comicData } from '../data.js';
import './comics.css';

import {
  useLocation,
  useNavigate,
  useParams
} from "react-router-dom";

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}

const comicsMap = {
	"among-the-silver-stars": 0,
	"dear-husband": 1,
	"the-twilight-of-magic": 2
}

class Reader extends Component {
	constructor(props) {
		super(props);
		console.log(this.props);
		this.state = {
			selected: comicsMap[this.props.router.params.comic]
		}
		this.selectComic = this.selectComic.bind(this);
	}
	selectComic(selected) {
		this.setState({selected});
	}
  render() {
	let { selected } = this.state;
	let comics = comicsData.comics;
	let selectedIssue = 0;
	if (Number.isInteger(selected) && (comics[selected].issues.length > 1)){
	  selectedIssue = null;
	}
	const comic = comicData[selected];
	if(selected > 0) {
		selectedIssue = 0;
	}
	return (
	<div className='hs-comics'>
		<Comic comic={comics[selected]} metadata={comic} selectedIssue={selectedIssue}/>
	</div>
	);
  }
}

export default withRouter(Reader);
