import React, { Component, Fragment } from 'react';
import Swiper from './Swiper';

class Nav extends Component {
	constructor(props) {
		super(props);
		this.handleKeyDown = this.handleKeyDown.bind(this);
	}
	
	handleKeyDown(e) {
		let { navBack, navNext } = this.props;
		let keyNum = e.keyCode;
		switch(keyNum){
			case 37:
				navBack();
				break;
			case 39:
				navNext();
				break;
			default:
				break;
		}
	}
	
  componentDidMount() {
    window.document.addEventListener("keydown", this.handleKeyDown);
  }

  componentWillUnmount() {
    window.document.removeEventListener("keydown", this.handleKeyDown);
  }
	
  render() {
	  let { navBack, navNext } = this.props;
	return (
		<div className="nav">
     	<button onClick={() => navBack()} className="back"></button>
		<button onClick={() => navNext()} className="next"></button>
   		</div>
    );
  }
}

export default Nav;
