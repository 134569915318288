import React, { Component, Fragment } from 'react';
import { comics as comicData } from '../data.js';

const buildURL = (dir, file) => {
	return `${dir}/${file}`;
};

const Shelf = ({books, type = 'item', featured = false}) => {
	let shelf = books.map(book => {
		const { title, cover, blurb, url } = book;
		return (	
		<div className="item">
			<a href={url}>
			<img src={cover} />
			<h4>{title}</h4>
			<div dangerouslySetInnerHTML={{__html: blurb}} />
			</a>
		</div>
		);
	});
	return (
		<div className='shelf'>
			{shelf}
		</div>
	);
};

class Lander extends Component {
	constructor(props) {
		super(props);
		this.selectItem = this.props.selectItem;
		this.handleClick = this.handleClick.bind(this);
	}
	handleClick(i) {
		this.selectItem(i);
	}
  render() {
	  let { arr, selectItem, dir, title, description=null } = this.props;
	  const renderLander = () => {
		  let landerImages = arr.map((item, i) => {
		  	let _dir = dir ? dir : item.dir;
		  	let src = buildURL(_dir, item.cover);
			 return (
			 	<img src={src} key={i} onClick={() => this.handleClick(i)} />
			 ); 
		  });
		  return (
		  	<div className="lander">
		  		{landerImages}
		  	</div>
		  );
	  };
	  let renderedLander = renderLander();
		if (!title) {
			let comics = comicData;
			let books = comics.map((comic) => {
				return {
					title: comic.title,
					url: comic.url,
					blurb: comic.description,
					collaborator: comic.collaborator,
					cover: comic.cover
				}
			});
			renderedLander = <Shelf books={books} />;
		}
	  let headText = `${(title) ? title : 'Free Webcomics' }`;
    return (
		<Fragment>
			<h1>{headText}</h1>
			{description &&
			<div dangerouslySetInnerHTML = {{__html: description}} />
			}
			{renderedLander}
		</Fragment>
    );
  }
}

export default Lander;
