import React, { Fragment } from 'react';
import './styles.css';
export default function Spotlight(spotlight) {
	const { name, genre, description, image, media } = spotlight.spotlight;
	const Media = () => {
		const renderedMedia = media.map(medium => {
			const { title, img, url } = medium;
			return (
				<div>
					<a href={url} target="_blank">
						<img src={img} />
						<h4>{title}</h4>
					</a>
				</div>
			);
		});
		return renderedMedia;
	};
	return (
		<Fragment>
		<h2 class="section">Featured Story: <i>{name}</i></h2>
		<div className="spotlight">
			<div className="content">
				<img src={image} />
				<div dangerouslySetInnerHTML = {{__html: description}} />
			</div>
		</div>
		<hr className="spot"/>
		<h3 className="spot">Media</h3>
		<div className="media">
			<Media/>
		</div>
		</Fragment>
	);
}